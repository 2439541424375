// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // for local
  // sign_in_provider:
  //   "10816445784-6kmho4r2tc1ua5ode1vstn3mei6fremo.apps.googleusercontent.com",
  sign_in_provider:
    "720795530325-ljso320ledh6q8j0u74obes9tkcnidfi.apps.googleusercontent.com",

  // base_url: "http://127.0.0.1:8000/",
  // fast_api_url: 'http://itd.quantiphi.com/auth/validate-token',
  // sign_in_provider:
  //   "692129646871-vfac2v64rfl5j5ap444erlcth4p0u4mq.apps.googleusercontent.com",
  // base_url: "https://itd.qdatalabs.com/",
  // base_url: "http://localhost:8000/",
  //qassure-qa
   base_url: "https://qqassure-qa.qdatalabs.com/",
  staging: "qa", // Only qa, dev
  // base_url: "https://qqassure.quantiphi.com/",
  // user_mng_url: "https://qqassure.quantiphi.com/usermanagement/api/",
  user_mng_url: "https://itd.qdatalabs.com/usermanagement/api/",
  // user_mng_url: "http://localhost:3000/api/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// http://107.178.211.190 -local
// 10816445784-6kmho4r2tc1ua5ode1vstn3mei6fremo.apps.googleusercontent.com -local

// 692129646871-vfac2v64rfl5j5ap444erlcth4p0u4mq.apps.googleusercontent.com -develop
// https://qqassure-dev.qdatalabs.com/ -develop

// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
