import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { CookieService } from "ngx-cookie";
import { environment } from "../environments/environment";

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from "@abacritt/angularx-social-login";
import { GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { AppRoutingModule } from "./app-routing.module";
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { AppComponent } from "./app.component";
import { PrimeNgModule } from "./shared/prime-ng/prime-ng.module";
import { AgGridModule } from "ag-grid-angular";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./shared/material/material.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HomeComponent } from "./components/home/home.component";
import { ConfirmationService, MessageService } from "primeng/api";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MaintenanceComponent } from "./components/maintenance/maintenance.component";
import { UsernotfoundComponent } from "./components/usernotfound/usernotfound.component";
// import { ParticlesModule } from "angular-particle";
import { LoaderModule } from "./loader/loader.module";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { ButtonModule } from "primeng/button";

import { IfPermissionDirective } from "./components/directive/if-permission.directive";
import { IfRoleDirective } from "./components/directive/if-role.directive";
import { MilestonesComponent } from "./components/project-details/milestones/milestones.component";
import { CookieModule } from "ngx-cookie";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { NgParticlesModule } from "ng-particles";
import { EditorModule } from "@tinymce/tinymce-angular";
import { CanDeactivateGuard } from "./guards/can-deactivate.guard";
import { LoaderInterceptor } from "./interceptors/loader.interceptor";
// Setting scopes for google drive access
// const googleLoginOptions: LoginOpt = {
//   scope: "https://www.googleapis.com/auth/drive",
// };

// let config = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(
//       environment.sign_in_provider,
//       googleLoginOptions
//     ),
//   },
// ]);

// export function provideConfig() {
//   return config;
// }
// ,https://www.googleapis.com/auth/drive'
const googleLoginOptions = {
  scopes: ["https://www.googleapis.com/auth/drive"],
  oneTapEnabled: false,
};
@NgModule({
  declarations: [
    AppComponent,
    MaintenanceComponent,
    UsernotfoundComponent,
    HomeComponent,
    IfRoleDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    AppRoutingModule,
    ReactiveFormsModule,
    PrimeNgModule,
    HttpClientModule,
    LoaderModule,
    PrimeNgModule,
    ButtonModule,
    NgParticlesModule,
    CookieModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    AgGridModule.withComponents([]),
  ],
  providers: [
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.sign_in_provider,
              googleLoginOptions
            ),
          },
        ],
        onError: (err) => {
          console.error("from social auth service", err);
        },
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    // useFactory: provideConfig,
    MessageService,
    CookieService,
    DynamicDialogRef,
    DynamicDialogConfig,
    CanDeactivateGuard,
    ConfirmationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
