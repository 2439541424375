<p-toast [style]="{ marginTop: '10px' }"></p-toast>
<!-- <app-loader [loader]="loading"></app-loader> -->
<div class="main-container">
  <div class="part-1">
    <p-carousel
      [value]="products"
      styleClass="custom-carousel"
      [numVisible]="1"
      [numScroll]="1"
      [circular]="true"
      [autoplayInterval]="5000"
      [showIndicators]="true"
      [showNavigators]="false"
      [orientation]="'horizontal'"
    >
      <ng-template let-product pTemplate="item">
        <div class="product-item-content">
          <img
            [src]="
              product?.image
                ? '../../../assets/HomeCarousel/' + product.image
                : product.file_url
            "
            class="product-image"
          />
        </div>
      </ng-template>
    </p-carousel>
  </div>

  <div class="part-2">
    <div class="logo">
      <img src="../../../assets/Logo2.png" />
    </div>
    <div class="sub-heading">
      <span class="sub-logo">
        InfoSec | CloudOps | Testing | Delivery Excellence
      </span>
      <div class="quality-policy">
        <div class="title">Quality Policy</div>
        <div class="content">
          " Quantiphi is committed to delivering high quality, best-in-class AI
          solutions, products and services in line with the statutory and
          regulatory requirements. We combine cutting-edge technologies with
          digital transformational practices that focus on continual
          improvements and innovations to continuously meet and exceed customer
          expectations. "
        </div>
      </div>
    </div>
    <div class="google-signin">
      <!-- <div
        class="google-btn pointer"
        (click)="signInWithGoogle()"
      >
        <div class="google-icon-wrapper">
          <img
            class="google-icon"
            src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
          />
        </div>
        <p class="btn-text"><b>Sign in with google</b></p>
        
      </div> -->
      <asl-google-signin-button
        shape="pill"
        client_id="client_id"
        class="google-signin"
        login_uri="http://localhost:4200/home"
        type="standard"
        size="large"
      ></asl-google-signin-button>
    </div>
  </div>
  <!-- login_uri="http://localhost:4200/home"  -->
  <div class="version">
    {{ version }}
  </div>
</div>
