import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, AfterViewChecked, ChangeDetectionStrategy } from '@angular/core';
import { LoaderService } from '../services/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loader',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, AfterViewChecked {
  isLoading: boolean = false;
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  private subscription: Subscription;
  constructor(private _loader: LoaderService, private cdr: ChangeDetectorRef) {

  }
  ngAfterViewChecked() {
    this.subscription = this._loader.isLoading.subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.cdr.detectChanges();
  }
  ngOnInit() {

  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
