import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  Router,
  CanLoad,
  Route,
  UrlSegment,
} from "@angular/router";
import { MessageService } from "primeng/api";
import { Observable } from "rxjs";
import { SharedService } from "../services/shared/shared.service";
import { ApiService } from "../services/token/api.service";

@Injectable({
  providedIn: "root",
})
export class LoginGuard implements CanActivate, CanLoad {
  constructor(
    private _router: Router,
    private _shared: SharedService,
    private msgs: MessageService,
    private _tokenService: ApiService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const currentToken: string = this._tokenService.getAuthToken();
    if (currentToken) {
      this._router.navigateByUrl("/list-projects");
      return false;
    }
    return true;
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    const currentToken: string = this._tokenService.getAuthToken();
    if (!localStorage.getItem("routeURL")) {
      if (
        segments &&
        segments[0] &&
        segments[1] &&
        segments[0]["path"] &&
        (segments[0]["path"] == "project-details" ||
          segments[0]["path"] == "verification") &&
        segments[1]["path"]
      ) {
        let temp = "";
        if (segments[2] && segments[2]["path"]) {
          if (segments[2]["path"] == "Info Security") temp = "infosec";
          else if (segments[2]["path"] == "Delivery Excellence")
            temp = "delivery";
          else if (segments[2]["path"] == "Dev Ops") temp = "devops";
          else if (segments[2]["path"] == "Testing") temp = "testing";
          this._shared.setProjectRoutingDetails(
            segments[0]["path"],
            segments[1]["path"],
            temp
          );
        } else
          this._shared.setProjectRoutingDetails(
            segments[0]["path"],
            segments[1]["path"]
          );
      }
    } else {
      if (
        segments &&
        segments[0] &&
        segments[1] &&
        segments[0]["path"] &&
        (segments[0]["path"] == "project-details" ||
          segments[0]["path"] == "verification") &&
        segments[1]["path"]
      ) {
        if (localStorage.getItem("routeURL") !== segments[0]["path"]) {
          let temp = "";
          if (segments[2] && segments[2]["path"]) {
            if (segments[2]["path"] == "Info Security") temp = "infosec";
            else if (segments[2]["path"] == "Delivery Excellence")
              temp = "delivery";
            else if (segments[2]["path"] == "Dev Ops") temp = "devops";
            else if (segments[2]["path"] == "Testing") temp = "testing";
            this._shared.setProjectRoutingDetails(
              segments[0]["path"],
              segments[1]["path"],
              temp
            );
          } else
            this._shared.setProjectRoutingDetails(
              segments[0]["path"],
              segments[1]["path"]
            );
        }

        this._shared.setProjectName(segments[1]["path"]);
        let data = this._shared.getProjectData(segments[1]["path"]);
        data.subscribe((res) => {
          this._shared.setQatalogStage(
            res["data"][0]["stage_qatalog_submission"]
          );
          this._shared.setVerificationStage(
            res["data"][0]["stage_qatalog_verification"]
          );

          this._shared.setDevopsQatalogStage(res["data"][0]["devops_qatalog"]);
          this._shared.setInfosecQatalogStage(
            res["data"][0]["infosec_qatalog"]
          );
          this._shared.setDeliveryQatalogStage(
            res["data"][0]["delivery_qatalog"]
          );
          this._shared.setTestingQatalogStage(
            res["data"][0]["testing_qatalog"]
          );
        });
      }
    }
    if (currentToken) {
      return true;
    }
    this._router.navigate(["/home"]);
    return false;
  }
}
