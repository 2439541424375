import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Subject, retry } from "rxjs";
import { SharedService } from "../shared/shared.service";
import * as moment from "moment";
import { format } from "path";
import _ from "lodash";

export interface kick_Off_Project {
  deal_id: string;
  account_lead: string;
  kickoff_date: string;
  project_lead: string;
  delivery_lead: string;
  sa: string;
  project_name: string;
  project_id: string;
  account_name: string;
  location: string;
  start_date: string;
  end_date: string;
  project_type: any;
  solution_type: any;
  region: any;
  environment: any;
  // production_data_access: string;
  using_cloud: string;
  cloud: string;
  cloud_owners: string;
  cloud_management: any;
  // access_to_cloud: any;
  // cloud_description: string;
  description: string;
  // contract_renewal: string;
  client_location: string;
  personal_data_shared: string;
  personal_data_view_q: string;
  personal_data_enter_q: string;
  laptop_owner: string;
  personal_data_download: string;
  personal_data_type: string;
  personal_data_fields: string;
  other_data: string;
  client_data_region: string;
  // instance_os: string;
  compliance_spoc: string;
  sow: File;
  msa: File;
  architecture: File;
  legal_doc?: File;
  brd: File;
  fsd: File;
}

@Injectable({
  providedIn: "root",
})
export class ApiService {
  projectCreated = new EventEmitter();
  projectSelected = new Subject();
  loadProjectListData = new Subject();
  loadWorkQData = new Subject();
  MetaDataSubject = new Subject();
  extendSession = new Subject();
  formOpened = new Subject();
  mom_updated = new Subject<any>();
  audit_master_update = new Subject<any>();
  user: any;
  constructor(private http: HttpClient, private _shared: SharedService) {}

  public getAccountList() {
    return this.http.get(environment.base_url + "get_account_list/");
  }
  public fetchProjectsFromDB() {
    return this.http.get(environment.base_url + "get_all_deal_id/");
  }
  public getAllDealIdsForException() {
    return this.http.get(environment.base_url + "get_onboarded_and_won_deals/");
  }
  public getAllDealIds() {
    return this.http.get(environment.base_url + "get_live_dealid/");
  }
  public getWonDealIds(parent_deal_id) {
    let body = {
      parent_deal_id: null,
    };
    body.parent_deal_id = parent_deal_id;
    return this.http.post(environment.base_url + "database_dealids/", body);
  }
  // public getAllQassurePresentDealIds() {
  //   let body = {
  //     designation: "admin",
  //     emp_id: this._shared.getEncodeUserEmail(),
  //     team: null,
  //   };
  //   return this.http.post(
  //     environment.base_url + "get_projects_project_list/",
  //     body
  //   );
  // }
  public getExtendedDealIds() {
    // let body = {
    //   designation: 'admin',
    //   emp_id: this._shared.getUserEmail(),
    //   team: null
    // };
    return this.http.get(environment.base_url + "project_list_deal_id/");
  }

  public getSowMsaDetailsFromQlegal(dealId: string) {
    let body = {
      deal_id: null,
    };
    body.deal_id = dealId;
    return this.http.post(
      environment.base_url + "getMSAandSOWSingedUrl/",
      body
    );
  }

  public publishMOM(teamEmailsList, mom_id) {
    // console.log(mailObjectList.cloudops);
    // let body = {
    //   mom_id: mom_id,
    //   cloudops: mailObjectList["cloudops"],
    //   infosec: mailObjectList["infosec"],
    // };
    // console.log(body);
    let body = {
      mom_id: null,
    };
    body.mom_id = mom_id;
    return this.http.post(environment.base_url + "publish_project_mom/", {
      ...body,
      // ...teamEmailsList,
    });
  }

  public momDraft(formData, projectData, attachments) {
    let formMomData = new FormData();
    //project data
    formMomData.append("deal_id", projectData.deal_id);
    formMomData.append("review_cycle", projectData.review_cycle);
    formMomData.append("review_cycle_id", projectData.review_cycle_id);

    // form data
    formMomData.append("call_type", formData.meeting_type);
    formMomData.append(
      "call_date",
      // new Date(formData.call_date).toISOString().slice(0, 10)
      moment(formData.call_date).format("YYYY-MM-DD")
    );
    formMomData.append("project_overview", formData.project_overview);

    let extra_email_to = formData.include_to_mail.reduce(
      (mailIdList, mailObj) => mailObj.value + "," + mailIdList,
      ""
    );
    extra_email_to = extra_email_to.replace(/,\s*$/, "");

    let meeting_links = "";
    if (formData.list_meeting_links.length > 0) {
      formData.list_meeting_links.forEach((link, index) => {
        if (link["meeting_links"] != "") {
          if (index == formData.list_meeting_links.length - 1)
            meeting_links += link["meeting_links"];
          else meeting_links += link["meeting_links"] + ",";
        }
      });
    }
    let other_links = "";
    if (formData.list_other_links.length > 0) {
      formData.list_other_links.forEach((link, index) => {
        if (link["other_links"] != "") {
          if (index == formData.list_other_links.length - 1)
            other_links += link["other_links"];
          else other_links += link["other_links"] + ",";
        }
      });
    }
    formMomData.append("meeting_recording_link", meeting_links);
    formMomData.append("other_required_links", other_links);
    formMomData.append("extra_email_to", extra_email_to);
    formMomData.append("action_items", formData.action_items);

    // pillar data
    let pillar_data = [];
    Object.keys(formData).forEach((key) => {
      let pillarVal = {};
      if (key.includes("_input")) {
        if (formData[key]) {
          let pillarName = key.split("_input")[0];
          if (pillarName == "delivery")
            pillarVal["pillar_name"] = "delivery_excellence";
          else pillarVal["pillar_name"] = pillarName;
          pillarVal["input"] = formData[key];

          pillarVal["pillar_spoc"] = formData[pillarName + "_spoc"]
            .map((val) => val.value)
            .join(",");
        }
        pillar_data.push(pillarVal);
      }
      // if (key.includes("_spoc")) {
      //   if (formData[key]) {
      //   }
      // }
    });

    formMomData.append("pillar_data", JSON.stringify(pillar_data));
    Object.keys(attachments).forEach((key) => {
      if (attachments[key]) {
        attachments[key].forEach((attachment) => {
          formMomData.append(key.toLowerCase(), attachment[0].file);
        });
      }
    });
    return this.http.post(
      environment.base_url + "project_mom_create_draft/",
      formMomData
    );
  }

  public getMOMData(dealID, reviewCycleID, designation) {
    let body = {
      deal_id: dealID,
      review_cycle_id: reviewCycleID,
      designation: designation,
    };
    return this.http.post(environment.base_url + "get_mom_details/", body);
  }

  public updateMOMData(formData, projectData, attachments) {
    let formMomData = new FormData();
    //project data
    formMomData.append("deal_id", projectData.deal_id);
    formMomData.append("review_cycle", projectData.review_cycle);
    formMomData.append("review_cycle_id", projectData.review_cycle_id);
    formMomData.append("mom_id", projectData.id);

    // form data
    formMomData.append("call_type", formData.meeting_type);
    formMomData.append(
      "call_date",
      // new Date(formData.call_date).toISOString().slice(0, 10)
      moment(formData.call_date).format("YYYY-MM-DD")
    );
    formMomData.append("project_overview", formData.project_overview);

    let extra_email_to = formData.include_to_mail.reduce(
      (mailIdList, mailObj) => mailObj.value + "," + mailIdList,
      ""
    );
    extra_email_to = extra_email_to.replace(/,\s*$/, "");
    let meeting_links = "";
    if (formData.list_meeting_links.length > 0) {
      formData.list_meeting_links.forEach((link, index) => {
        if (link["meeting_links"] != "") {
          if (index == formData.list_meeting_links.length - 1)
            meeting_links += link["meeting_links"];
          else meeting_links += link["meeting_links"] + ",";
        }
      });
    }
    let other_links = "";
    if (formData.list_other_links.length > 0) {
      formData.list_other_links.forEach((link, index) => {
        if (link["other_links"] != "") {
          if (index == formData.list_other_links.length - 1)
            other_links += link["other_links"];
          else other_links += link["other_links"] + ",";
        }
      });
    }
    formMomData.append("meeting_recording_link", meeting_links);
    formMomData.append("other_required_links", other_links);
    formMomData.append("extra_email_to", extra_email_to);
    formMomData.append("action_items", formData.action_items);

    // pillar data
    let pillar_data = [];
    Object.keys(formData).forEach((key) => {
      let pillarVal = {};
      if (key.includes("_input")) {
        if (formData[key]) {
          let pillarName = key.split("_input")[0];
          if (pillarName == "delivery")
            pillarVal["pillar_name"] = "delivery_excellence";
          else pillarVal["pillar_name"] = pillarName;
          pillarVal["input"] = formData[key];

          pillarVal["pillar_spoc"] = formData[pillarName + "_spoc"]
            .map((val) => val.value)
            .join(",");
          let pillar_id = null;
          projectData.pillar_details.forEach((pillar) => {
            if (pillarName == "delivery") {
              if (
                pillar.pillar_name == "delivery_excellence" ||
                pillar.pillar_name == "delivery"
              ) {
                pillar_id = pillar.id;
              }
            } else {
              if (pillar.pillar_name == pillarName) {
                pillar_id = pillar.id;
              }
            }
            // if (pillar.pillar_name == pillarName) {
            //   pillar_id = pillar.id;
            // }
          });
          if (pillar_id != null) pillarVal["id"] = pillar_id;
          pillar_data.push(pillarVal);
        }
      }
    });
    formMomData.append("pillar_data", JSON.stringify(pillar_data));
    // Object.keys(attachments).forEach((key) => {
    //   if (attachments[key] && typeof attachments[key] == "object") {
    //     formMomData.append(key.toLowerCase(), attachments[key]);
    //   }
    // });
    Object.keys(attachments).forEach((key) => {
      if (attachments[key]) {
        attachments[key].forEach((attachment) => {
          if (attachment[0]?.file) {
            formMomData.append(key.toLowerCase(), attachment[0].file);
          }
        });
      }
    });
    formMomData.append("attachment_deleted", formData.delete_document_ids);
    return this.http.post(
      environment.base_url + "update_project_mom/",
      formMomData
    );
  }

  public deleteMOMDraft(mom_id) {
    let body = {
      mom_id: mom_id,
    };
    return this.http.post(environment.base_url + "delete_project_mom/", body);
  }

  public getAuditorUser(emp_id, deal_id) {
    let body = {
      emp_id: null,
      deal_id: null,
    };
    // body.emp_id = !emp_id ? "" : this.tokenGeneraterEmp(emp_id);
    body.emp_id = !emp_id ? "" : emp_id;
    body.deal_id = deal_id;
    return this.http.post(environment.base_url + "add_auditor/", body);
  }

  public getProjects() {
    let designation = this._shared.getDesignation();
    if (designation == "auditor") {
      return this.http.post(environment.base_url + "get_auditor_projects/", {
        emp_id: this._shared.getEncodeUserEmail(),
      });
    } else {
      return this.http.post(
        environment.base_url + "get_projects_project_list/",
        {
          designation: this._shared.getDesignation(),
          emp_id: this._shared.getEncodeUserEmail(),
        }
      );
    }
  }
  public getProjectsAdmin() {
    return this.http.post(environment.base_url + "get_projects_project_list/", {
      designation: "admin",
      emp_id: this._shared.getEncodeUserEmail(),
    });
  }
  public getWorkQList(des, team) {
    return this.http.post(environment.base_url + "get_workq_list/", {
      designation: des,
      team: team,
    });
  }
  public getProjectTikets(deal_Id, designation, team) {
    return this.http.post(environment.base_url + "get_project_tickets/", {
      designation: designation,
      deal_id: deal_Id,
      team: team,
    });
  }
  public getProjectDetails(deal_id) {
    return this.http.post(environment.base_url + "dealid_details/", {
      deal_id: deal_id,
    });
    // this.tokenGenerater(deal_id),
  }
  public getParentProjectDetails(deal_id) {
    return this.http.post(environment.base_url + "parent_dealid_details/", {
      deal_id: deal_id,
    });
    // this.tokenGenerater(deal_id),
  }
  public setProjectExtension(
    extended_title,
    old_extendedIds,
    extended_deal_ids,
    sow_start_date,
    sow_end_date,
    parent_deal_id,
    project_name,
    projectId,
    sow: File
  ) {
    const projectData = new FormData();
    if (extended_title == "Add")
      projectData.append("extended_deal_ids", extended_deal_ids);
    else projectData.append("deal_id", extended_deal_ids);
    projectData.append("sow_start_date", sow_start_date);
    projectData.append("sow_end_date", sow_end_date);
    projectData.append("parent_deal_id", parent_deal_id);
    projectData.append("project_name", project_name);
    projectData.append("workday_project_id", projectId);
    if (typeof sow == "object") projectData.append("sow", sow);
    // else projectData.append("sow", null);
    if (old_extendedIds) {
      extended_deal_ids = old_extendedIds.toString() + "," + extended_deal_ids;
    }
    // this.http
    //   .post(environment.base_url + "update_workq_deadlines/", {
    //     end_date: sow_end_date,
    //     project_name: project_name,
    //   })
    //   .subscribe();

    if (extended_title == "Add")
      return this.http.post(
        environment.base_url + "project_extension_api/",
        projectData
      );
    else
      return this.http.post(
        environment.base_url + "update_extended_projects/",
        projectData
      );
  }

  public creatProject(body: any, upload_details) {
    console.log(body);

    let practice_array = new Array(body.practice_type);
    const projectData = new FormData();
    // Page 1

    projectData.append("deal_id", body.deal_id);
    if (body.dade_conducted)
      projectData.append("dade_kickoff_date", body.kickoff_date);
    projectData.append(
      "is_dade_conducted",
      !body.dade_conducted ? "False" : "True"
    );
    projectData.append("project_name", body.project_name);
    projectData.append("project_id", body.project_id);
    projectData.append("pri_prj_hierarchy", body.pri_prj_hierarchy);
    projectData.append("project_by_id", body.project_by_id);
    projectData.append("project_description", body.description);
    // if (body.is_extended)
    //   projectData.append("parent_deal_id", body.extended_deal_id);
    // projectData.append("is_extended", !body.is_extended ? "false" : "true");
    projectData.append("is_extended", "false");
    projectData.append("industry_verticals", body.industry_verticals);
    projectData.append("practice_type", body.practice_type); //
    projectData.append("industry_segments", body.industry_segments);
    projectData.append("new_solution_type", body.new_solution_type); //Solution Type
    projectData.append("account_name", body.account_name);
    projectData.append("start_date", body.start_date);
    projectData.append("end_date", body.end_date);
    // projectData.append(
    //   "contract_renewal",
    //   !body.contract_renewal ? "no" : "yes"
    // );
    projectData.append("development_method", body.development_method);
    projectData.append("project_type", body.project_type); //Project Scope
    projectData.append("team_strength", body.team_strength);
    projectData.append("solution_type", body.solution_type); //Workload Types as per SOW
    projectData.append("delivery_lead", body.delivery_lead);
    projectData.append("operational_unit", body.operational_unit);
    projectData.append("portfolio_lead", body.portfolio_lead);
    projectData.append("account_lead", body.account_lead); //GTM Lead
    projectData.append("project_lead", body.project_lead);
    projectData.append("sa", body.sa); //Solution Architect
    projectData.append("compliance_spoc", body.compliance_spoc); //Project SPOC
    if (
      body.compliance_spoc_2 &&
      body.compliance_spoc_2 != "" &&
      body.compliance_spoc_2 != null
    )
      projectData.append("compliance_spoc_2", body.compliance_spoc_2); //Project SPOC
    projectData.append("region", body.region);

    // Page 2
    if (body.architecture)
      projectData.append("architecture", body.architecture);
    if (!body.sow_signed)
      projectData.append("sow_signed_reason", body.sow_signed_reason);
    projectData.append("sow_signed", !body.sow_signed ? "False" : "True");

    if (upload_details.brd.length)
      projectData.append(
        "brd",
        upload_details.brd[0].url
          ? upload_details.brd[0].url
          : upload_details.brd[0]
      );

    if (upload_details.architecture.length) {
      projectData.append(
        "architecture",
        upload_details.architecture[0].url
          ? upload_details.architecture[0].url
          : upload_details.architecture[0]
      );
    }

    if (upload_details.contractual_docs.length) {
      const userUploadedContractualDocs = upload_details[
        "contractual_docs"
      ].filter((elm) => !["pipedrive", "qLegal"].includes(elm.source));

      if (userUploadedContractualDocs.length) {
        userUploadedContractualDocs.forEach((elm) => {
          projectData.append("contractual_doc", elm.url ? elm.url : elm);
        });
      }
    }

    if (upload_details.legal_doc.length)
      projectData.append(
        "legal_doc",
        upload_details.legal_doc[0].url
          ? upload_details.legal_doc[0].url
          : upload_details.legal_doc[0]
      );

    // Page 3
    projectData.append("location", body.location); //Location of service
    projectData.append("client_location", body.client_location);

    // Page 4
    projectData.append("laptop_owner", body.laptop_owner);
    // projectData.append("instance_os", body.instance_os);
    // projectData.append("migration", !body.migration ? "False" : "True");
    if (body.using_cloud) {
      projectData.append("cloud", body.cloud); //Cloud Service Provider
      projectData.append("cloud_stack", body.cloud_stack);
      projectData.append("cloud_owners", body.cloud_owners);
      projectData.append("cloud_management", body.cloud_management);
      // projectData.append("environment", body.environment);
      // projectData.append("cloud_description", body.cloud_description); //Environment Description
      // projectData.append("access_to_cloud", body.access_to_cloud);
    }
    projectData.append("using_cloud", !body.using_cloud ? "False" : "True");

    // Page 5
    // projectData.append(
    //   "production_data_access",
    //   !body.production_data_access ? "False" : "True"
    // );
    if (body.personal_data_shared) {
      projectData.append("personal_data_type", body.personal_data_type);
      projectData.append(
        "personal_data_view_q",
        !body.personal_data_view_q ? "False" : "True"
      );
      projectData.append(
        "personal_data_enter_q",
        !body.personal_data_enter_q ? "False" : "True"
      );
      projectData.append(
        "personal_data_download",
        !body.personal_data_download ? "False" : "True"
      );
      projectData.append("personal_data_fields", body.personal_data_fields);
    }
    projectData.append(
      "personal_data_shared",
      !body.personal_data_shared ? "False" : "True"
    );
    projectData.append("other_data", body.other_data);
    projectData.append("client_data_region", body.client_data_region);
    projectData.append("other_data", body.other_data);
    projectData.append("bcp_required_in_msa_sow", body.bcp_required_in_msa_sow);
    // projectData.append("change_request", body.change_request);

    // for (let pair of projectData.entries()) {
    // }
    //Cloud - Yes and Personal Data -No
    console.log(projectData);
    projectData.append("criticality_assessment", body.criticality_assessment);
    return this.http.post(
      environment.base_url + "project_kickoff/",
      projectData
    );
  }
  public saveDraftChanges(deal_id, upload_details, body: any) {
    const projectData = new FormData();

    projectData.append("deal_id", deal_id);

    if (upload_details.brd.length)
      projectData.append(
        "brd",
        upload_details.brd[0].url
          ? upload_details.brd[0].url
          : upload_details.brd[0]
      );

    if (upload_details.architecture.length) {
      projectData.append(
        "architecture",
        upload_details.architecture[0].url
          ? upload_details.architecture[0].url
          : upload_details.architecture[0]
      );
    }

    if (upload_details.contractual_docs.length) {
      const userUploadedContractualDocs = upload_details[
        "contractual_docs"
      ].filter((elm) => !["pipedrive", "qLegal"].includes(elm.source));

      if (userUploadedContractualDocs.length) {
        userUploadedContractualDocs.forEach((elm) => {
          projectData.append("contractual_doc", elm.url ? elm.url : elm);
        });
      }
    }

    if (upload_details.legal_doc.length)
      projectData.append(
        "legal_doc",
        upload_details.legal_doc[0].url
          ? upload_details.legal_doc[0].url
          : upload_details.legal_doc[0]
      );

    if (body) {
      projectData.append("details", body);
    }
    return this.http.post(
      environment.base_url + "create_draft_project/",
      projectData
    );
  }
  public fetchDraftData() {
    return this.http.get(environment.base_url + "fetch_all_draft_projects/");
  }
  public discardDraftChanges(deal_id) {
    let body = {
      deal_id: null,
    };
    body.deal_id = deal_id;
    return this.http.post(
      environment.base_url + "discard_draft_project/",
      body
    );
  }
  public pipeDrive(body) {
    return this.http.post(environment.base_url + "pipedrive_check/", body);
  }
  public getIndustryData(type) {
    let body = {
      type: null,
    };
    body.type = type;
    return this.http.post(environment.base_url + "get_industry_data/", body);
  }
  public getOtherData() {
    return this.http.get(environment.base_url + "get_other_data/");
  }
  public getExceptionDropdownData() {
    return this.http.get(
      environment.user_mng_url + "exception_manager/get_exceptions_data"
    );
  }
  public getProjectDatabaseDropdownValues(type: string = "applicable_deals") {
    let body = {
      type: type,
    };
    return this.http.post(
      environment.base_url + "project_database_dropdown_values/",
      body
    );
  }

  public getProjectListData(designation: string, emp_id: string) {
    let body = {
      designation: null,
      emp_id: null,
      // team: null,
    };
    body.designation = designation;
    body.emp_id = emp_id;
    // body.team = team;

    return this.http.post(
      environment.base_url + "get_projects_project_list/",
      body
    );
  }
  public getProjectListDataDateWise(date1: any, date2: any, type: any) {
    let body = {
      start_date: null,
      end_date: null,
    };
    body.start_date = date1;
    body.end_date = date2;
    if (type == "Start Date") {
      return this.http.post(environment.base_url + "get_by_start_date/", body);
    } else {
      return this.http.post(environment.base_url + "get_by_end_date/", body);
    }
  }
  public getProjectListDataWithFilter(
    designation: string,
    emp_id: string,
    current_stage: string
  ) {
    let body = {
      designation: null,
      emp_id: null,
      current_stage: null,
    };
    body.designation = designation;
    body.emp_id = emp_id;
    body.current_stage = current_stage;

    return this.http.post(
      environment.base_url + "get_projects_project_list/",
      body
    );
  }

  public getIndividualProjectData(deal_id) {
    let body = {
      deal_id: null,
    };
    body.deal_id = deal_id;

    return this.http.post(
      environment.base_url + "get_data_project_list/",
      body
    );
  }

  public getSelectedProjectListData(deal_id: any) {
    let body = {
      deal_id: null,
    };
    body.deal_id = deal_id;

    return this.http.post(environment.base_url + "project_detail/", body);
  }

  public getAcceleratorData(prj_id: any) {
    let body = {
      prj_id: null,
    };
    body.prj_id = prj_id;
    return this.http.post(
      environment.base_url + "get_project_accelerators/",
      body
    );
  }
  public getUserByTeam(team) {
    let body = {
      team: team,
    };
    return this.http
      .post(environment.user_mng_url + "users/list/user_list_by_team", body)
      .pipe(retry(3));
  }

  public getClosureDocuments(dealId) {
    let body = {
      deal_id: null,
    };
    body.deal_id = dealId;

    return this.http.post(
      environment.base_url + "fetch_closure_artifacts/",
      body
    );
  }
  public getSegmentsAndAcceleratorDetails(dealId) {
    let body = {
      deal_id: null,
    };
    body.deal_id = dealId;

    return this.http.post(
      environment.base_url + "fetch_accelerator_details/",
      body
    );
  }
  public closeProject(formValues: any, totalEffortsSaved: number) {
    const closeProjData = new FormData();
    let questionnaire = [];
    let reusableData = [];

    const formControls = {
      deliverablesCompleted:
        "All deliverables completed & milestones delivered ?",
      openIssue: "All open issues have got resolved ?",
      accessRevoked:
        "Access to clients applications and environment for all the members has been revoked ?",
      handover: "Any handover or training conducted with client ?",
      lessonsLearnt:
        "Have we captured any 'Lessons Learnt' in the project? Are lessons Learnt documented for this project?",
      kpiArchived: "KPIs/SLAs were achieved?",
      reusableAssets:
        "Did we develop any Unique Features / Wow factors that are totally new in this area?",
      futureEngagement:
        "Any further engagement opportunity foreseen post the current project?",
      membersReleased: "Team members are released from the project ?",
      cloudInstance: "Deleted / transferred Cloud instance (if any) ?",
      dataCleanup:
        "All the client data available in Quantiphi systems are cleaned up/ archived (Refer client's MSA/SOW controls for 'Data and Information') ? ",
    };

    for (let [control, question] of Object.entries(formControls)) {
      if (formValues[control]) {
        let obj = {
          question: question,
          response: formValues[control] ? formValues[control].value : null,
          reason: formValues[control + "Reason"]
            ? formValues[control + "Reason"]
            : null,
        };
        questionnaire.push(obj);
      }
    }

    if (formValues.possibleAccelerators?.length) {
      formValues.possibleAccelerators.forEach((accelerator) => {
        reusableData.push({
          description: accelerator.possibleAccelerator,
          point_of_contact: accelerator.poc.name,
          have_attachment: accelerator.proof ? true : false,
        });
      });
    }

    closeProjData.append("deal_id", formValues.deal_id);
    closeProjData.append("questionnaire", JSON.stringify(questionnaire));
    closeProjData.append("reuseable_data", JSON.stringify(reusableData));

    closeProjData.append("pl_signoff", formValues.pl_signoff);

    closeProjData.append(
      "expected_efforts_saved",
      totalEffortsSaved.toString()
    );

    if (formValues.actualEffortsSaved)
      closeProjData.append(
        "actual_efforts_saved",
        formValues.actualEffortsSaved
      );

    if (formValues.customer_feedback) {
      closeProjData.append("customer_feedback", formValues.customer_feedback);
    }

    if (formValues.fsd_brd) {
      closeProjData.append("fsd_brd", formValues.fsd_brd);
    }

    if (formValues.client_signoff) {
      closeProjData.append("client_signoff", formValues.client_signoff);
    }

    if (formValues.closure_report) {
      closeProjData.append("doc", formValues.closure_report);
    }

    closeProjData.append(
      "reusable",
      formValues.reusableAssets?.label === "Yes" ? "True" : "False"
    );

    closeProjData.append("comments", formValues.comments);

    if (formValues.possibleAccelerators?.length) {
      formValues.possibleAccelerators.forEach((accelerator, index) => {
        if (accelerator.proof) {
          closeProjData.append(
            "reusable_attachment_" + index,
            accelerator.proof.url ? accelerator.proof.url : accelerator.proof
          );
        }
      });
    }

    return this.http.post(
      environment.base_url + "close_project/",
      closeProjData
    );
  }

  public SASignOff(body) {
    const SASignOff = new FormData();
    SASignOff.append("architecture", body.architecture);
    SASignOff.append("deal_id", body.deal_id);
    SASignOff.append("sa_signoff", body.sa_signoff);
    SASignOff.append("tdd", body.tdd);
    SASignOff.append("github_link", body.github_link ? body.github_link : null);
    SASignOff.append("sa_feedback", body.feedback);

    return this.http.post(environment.base_url + "sa_signoff/", SASignOff);
  }

  public updateDetails(body) {
    const updateFileData = new FormData();
    const updateFileDataWorkq = new FormData();

    updateFileData.append("deal_id", body.deal_id);
    updateFileData.append("prj_id", body.prj_id);
    updateFileData.append("project_name", body.project_name);
    updateFileData.append("pri_prj_hierarchy", body.pri_prj_hierarchy);
    updateFileData.append("project_by_id", body.project_by_id);
    updateFileData.append("project_description", body.project_description);
    updateFileData.append(
      "dag_remark",
      body.dag_remark ? body.dag_remark.trim() : ""
    );
    updateFileData.append("operational_unit", body.operational_unit);
    updateFileData.append("region", body.region);
    // updateFileData.append("is_extended", body.is_extended);
    // if (body["is_extended"] && body["is_extended"] === "True")
    //   updateFileData.append("parent_deal_id", body.parent_deal_id);
    updateFileData.append("industry_verticals", body.industry_verticals);
    updateFileData.append("practice_type", body.practice_type);
    updateFileData.append("industry_segments", body.industry_segments);
    updateFileData.append("new_solution_type", body.new_solution_type);
    updateFileData.append("development_method", body.development_method);
    updateFileData.append("team_strength", body.team_strength);
    updateFileData.append("cloud_stack", body.cloud_stack);
    // updateFileData.append("change_request", body.change_request);
    // updateFileData.append("migration", body.migration);
    updateFileData.append("location", body.location);
    updateFileData.append("start_date", body.start_date);

    updateFileData.append("dade_conducted", body.dade_conducted);
    if (body.dade_conducted == "True")
      updateFileData.append("kickoff_date", body.kickoff_date);
    updateFileData.append("end_date", body.end_date);
    updateFileData.append("project_type", body.project_type);
    updateFileData.append("solution_type", body.solution_type);
    // updateFileData.append(
    //   "production_data_access",
    //   body.production_data_access
    // );
    updateFileData.append("using_cloud", body.using_cloud);
    updateFileData.append("cloud", body.cloud);
    // updateFileData.append("environment", body.environment);
    // updateFileData.append("cloud_description", body.cloud_description);
    updateFileData.append("cloud_owners", body.cloud_owners);
    updateFileData.append("cloud_management", body.cloud_management);
    // updateFileData.append("access_to_cloud", body.access_to_cloud);
    // updateFileData.append("contract_renewal", body.contract_renewal);
    updateFileData.append("client_location", body.client_location);
    updateFileData.append("personal_data_shared", body.personal_data_shared);
    updateFileData.append("personal_data_view_q", body.personal_data_view_q);
    updateFileData.append("personal_data_enter_q", body.personal_data_enter_q);
    updateFileData.append("laptop_owner", body.laptop_owner);
    updateFileData.append(
      "personal_data_download",
      body.personal_data_download
    );
    if (body.is_sow_signed == "False")
      updateFileData.append("sow_signed_reason", body.is_sow_signed_reason);
    updateFileData.append("sow_signed", body.is_sow_signed);
    updateFileData.append("personal_data_type", body.personal_data_type);
    updateFileData.append("personal_data_fields", body.personal_data_fields);
    updateFileData.append("other_data", body.other_data);
    updateFileData.append("client_data_region", body.client_data_region);
    // updateFileData.append("instance_os", body.instance_os);
    // updateFileData.append("portfolio_lead", body.portfolio_lead);
    updateFileData.append("update_comment", body.comments);

    if (body.project_status)
      updateFileData.append("project_status", body.project_status);
    // For workq end date
    updateFileDataWorkq.append("end_date", body.end_date);
    updateFileDataWorkq.append("project_name", body.project_name);
    updateFileDataWorkq.append("project_name", body.project_name);
    // this.http
    //   .post(
    //     environment.base_url + "update_workq_deadlines/",
    //     updateFileDataWorkq
    //   )
    //   .subscribe();

    return this.http.post(
      environment.base_url + "update_project_details/",
      updateFileData
    );
  }
  public updateProjectStatuts(deal_id, project_status, reason) {
    const updateFileData = new FormData();
    updateFileData.append("deal_id", deal_id);
    updateFileData.append("project_status", project_status);
    updateFileData.append("reason", reason);
    return this.http.post(
      environment.base_url + "update_project_status/",
      updateFileData
    );
  }
  public updateProjectAccelerators(accDetail: any, id: number) {
    let body = {
      accelerators: [],
    };
    body.accelerators.push({
      id: id,
      acc_name: accDetail.accName,
      efforts_saved: accDetail.effortSaved,
      acc_applicable: accDetail.accApplicable,
      acc_reason: accDetail.accReason ? accDetail.accReason : null,
      source: accDetail.source || null,
    });

    return this.http.post(
      environment.base_url + "update_project_accelerators/",
      body
    );
  }

  public updateProjectAcceleratorsOnClosure(accDetail: any[]) {
    let body = {
      accelerators: [],
    };
    accDetail.forEach((acc) => {
      body.accelerators.push({
        id: acc.id,
        acc_name: acc.acceleratorName,
        acc_applicable: acc.status,
        acc_reason: acc.reason ? acc.reason : null,
        source: acc.source || null,
      });
    });

    return this.http.post(
      environment.base_url + "update_project_accelerators/",
      body
    );
  }

  //Update Files
  public updateFile(deal_Id: any, file_type: string, file: File) {
    const updateFileData = new FormData();
    updateFileData.append("deal_id", deal_Id);
    updateFileData.append("file_type", file_type);
    updateFileData.append(file_type, file);
    return this.http.post(
      environment.base_url + "update_file/",
      updateFileData
    );
  }
  public uploadMultipleFile(
    deal_Id: any,
    file_type: string,
    source: string,
    file: File
  ) {
    const updateFileData = new FormData();
    if (file_type == "final_architecture") file_type = "architecture";
    updateFileData.append("deal_id", deal_Id);
    updateFileData.append("file_type", file_type);
    updateFileData.append(file_type, file);
    updateFileData.append("source", source);

    return this.http.post(
      environment.base_url + "add_multiple_doc/",
      updateFileData
    );
  }
  public getExtendedDealIdsDetails(deal_ids: string) {
    const updateFileData = new FormData();
    updateFileData.append("extended_deal_ids", deal_ids);
    return this.http.post(
      environment.base_url + "extended_projects/",
      updateFileData
    );
  }
  //Update Leads
  public updateLead(
    deal_id: any,
    old_user: string,
    new_user: string,
    user_type: string
  ) {
    let body = {
      deal_id: null,
      old_user: null,
      new_user: null,
      user_type: null,
    };
    body.deal_id = deal_id;
    body.old_user = old_user;
    body.new_user = new_user;
    body.user_type = user_type;

    return this.http.post(environment.base_url + "update_user/", body);
  }

  public projectListFilter(designation: string, projectStatus?: string) {
    let body = {
      designation: null,
    };
    if (projectStatus != "") body["project_status"] = projectStatus;

    body.designation = designation;

    return this.http.post(environment.base_url + "project_list_filter/", body);
  }
  public projectDatabaseFilter(designation: string) {
    let body = {
      designation: null,
    };
    body.designation = designation;

    return this.http.get(environment.base_url + "project_database_filter/");
  }
  public getWonDealFilters() {
    return this.http.get(environment.base_url + "won_deal_filter/");
  }

  public getReportFilter(reportType: string) {
    let body = {
      type: null,
    };
    body.type = reportType;
    return this.http.post(
      environment.base_url + "get_all_reports_filter/",
      body
    );
  }
  public getProjectMilestoneFilters() {
    return this.http.get(environment.base_url + "milestones_report_filter/");
  }
  public getExceptionReportFilters() {
    return this.http.get(
      environment.base_url + "get_exception_manager_filters/"
    );
  }

  public getFeedbackFilters() {
    return this.http.get(environment.base_url + "get_feedback_filters/");
  }

  public getExtendedDealFilters() {
    return this.http.get(
      environment.base_url + "extensions_to_be_linked_projects_report_filters/"
    );
  }

  public getTbdDealsFilters() {
    return this.http.get(
      environment.base_url + "tbd_deals_operational_report_filters/"
    );
  }

  public assignRole(role, emp_id) {
    let body = {
      email_id: emp_id,
      role: role,
    };

    return this.http.post(environment.user_mng_url + "users/create", body);
  }

  public projectListExport(dealIds) {
    let body = {
      deal_ids: dealIds,
    };
    return this.http.post(environment.base_url + "export_maindetails/", body);
  }
  public marketingProjectListExport(projectsList) {
    // const projectData = new FormData();
    // projectData.append("project_list", projectsList)
    return this.http.post(environment.base_url + "marketing_export/", {
      data: projectsList,
    });
  }
  public getExtraProjectDetails(deal_id: string) {
    let body = {
      deal_id: null,
    };
    body.deal_id = deal_id;

    return this.http.post(
      environment.base_url + "get_extra_project_detail/",
      body
    );
  }

  public getPhaseAndSubPhases(team) {
    return this.http.post(environment.base_url + "get_phase_subphase/", {
      team: team == "CloudOps" ? "devops" : team,
    });
  }

  applyFilter(event) {
    return this.http.post(
      environment.base_url + "get_filtered_projects_list/",
      event
    );
  }

  getAuditCycle(deal_id) {
    let body = {
      deal_id: null,
    };
    body.deal_id = deal_id;
    return this.http.post(environment.base_url + "get_audit_cycle/", body);
  }

  getMarketingDocuments(deal_id: string) {
    let body = {
      deal_id: null,
    };
    body.deal_id = deal_id;
    return this.http.post(
      environment.base_url + "get_marketing_documents/",
      body
    );
  }

  dagClose(deal_id, comment) {
    let body = {
      deal_id: null,
      dag_closed_comment: null,
    };
    body.deal_id = deal_id;
    body.dag_closed_comment = comment;
    return this.http.post(environment.base_url + "dag_close/", body);
  }
  uploadOrRemoveDoc(doc) {
    const updateFileData = new FormData();
    updateFileData.append("doc_id", doc.doc_id);
    updateFileData.append("action", doc.action);
    updateFileData.append("deal_id", doc.deal_id);
    updateFileData.append(
      "file_type",
      doc.file_type == "final_architecture" ? "architecture" : doc.file_type
    );
    updateFileData.append("files", doc.files);
    return this.http.post(
      environment.base_url + "remove_or_reupload_document/",
      updateFileData
    );
  }

  getDrAccelerator(dealId: string) {
    let body = {
      deal_id: null,
    };
    body.deal_id = dealId;
    return this.http.post(environment.base_url + "getQCapsAccelerator/", body);
  }
  getAllAccelerators() {
    return this.http.get(
      environment.base_url + "getQCapsAcceleratorDropdownValues/"
    );
  }

  deleteAccelerator(accelerator_id: number) {
    let body = {
      accelerators: null,
    };
    body.accelerators = [accelerator_id];
    return this.http.post(
      environment.base_url + "delete_project_accelerators/",
      body
    );
  }

  addAccelerator(prjId: string, accelerators: any[]) {
    let body = {
      prj_id: prjId,
      accelerators: accelerators,
    };
    return this.http.post(environment.base_url + "add_accelerators/", body);
  }

  saveClosureDraft(dealId: string, form: any) {
    let formValue = _.cloneDeep(form);

    const body = new FormData();

    body.append("deal_id", dealId);

    if (form.customer_feedback) {
      body.append("customer_feedback", form.customer_feedback);
    }

    if (form.fsd_brd) {
      body.append("fsd_brd", form.fsd_brd);
    }

    if (form.client_signoff) {
      body.append("client_signoff", form.client_signoff);
    }

    if (form.closure_report) {
      body.append("doc", form.closure_report);
    }

    if (formValue.possibleAccelerators.length) {
      formValue.possibleAccelerators.forEach((accelerator, index) => {
        if (accelerator.proof) {
          body.append(
            "reusable_attachment_" + index,
            accelerator.proof.url ? accelerator.proof.url : accelerator.proof
          );
        }
      });
    }

    delete formValue.customer_feedback;
    delete formValue.fsd_brd;
    delete formValue.client_signoff;
    delete formValue.closure_report;

    formValue.possibleAccelerators = formValue.possibleAccelerators.map(
      (elm) => {
        return {
          possibleAccelerator: elm.possibleAccelerator,
          poc: elm.poc,
          have_attachment: elm.proof ? true : false,
        };
      }
    );
    body.append("data", JSON.stringify(formValue));

    return this.http.post(
      environment.base_url + "create_or_get_project_closure_draft/",
      body
    );
  }

  fetchClosureDraftDetails(dealId: string) {
    return this.http.get(
      environment.base_url +
        `create_or_get_project_closure_draft/?deal_id=${dealId}`
    );
  }
  updateProjectCriticalityData(dealID, projectCriticalityAssessmentData) {
    /**
     * sprint 39 Project Criticality Assessment update data for project criticality API
     */
    let payload = {
      deal_id: dealID,
      criticality_assessment: projectCriticalityAssessmentData,
    };

    return this.http.post(
      environment.base_url + "update_project_criticality/",
      payload
    );
  }
}
