<style>
    *{
        font-family: 'Roboto' , sans-serif;
        background-color: #9beaf2;
        /* #26d1e4 */
    }
    #body
    {
        padding-top: 10%;
        text-align: center;
    }
    </style>
    <body >
    <div id="body">
        <img src="../../../assets/images/unauthorized.png" style ="width:auto;height:100px"  alt="server under maintenance">
        <h1>Oops!</h1>
        <h2> Sorry! You are not authorized to access this portal.</h2>
        <div>
            <p>
                Please contact the QAssure team for any further queries.
            </p>
            <p>
                — Team QAssure</p>
        </div>
        <!-- <p> -->
            <!-- <a class="dropdown-item waves-light" mdbWavesEffect (click)="signOut()"> -->
                <!-- <h6>Log out and try some different Account</h6> -->
              <!-- </a> -->
        <!-- </p> -->
    </div>
    </body>
    