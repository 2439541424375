import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { LoginGuard } from "./guards/login.guard";
import { MaintenanceComponent } from "./components/maintenance/maintenance.component";
import { UsernotfoundComponent } from "./components/usernotfound/usernotfound.component";
import { FeedbackComponent } from "./components/feedback/feedback.component";

const routes: Routes = [
  { path: "home", canActivate: [LoginGuard], component: HomeComponent },
  {
    path: "",
    loadChildren: () =>
      import("./components/main/main.module").then((m) => m.MainModule),
    canLoad: [LoginGuard],
  },
  {
    path: "maintenance",
    canActivate: [LoginGuard],
    component: MaintenanceComponent,
  },
  {
    path: "usernotfound",
    canActivate: [LoginGuard],
    component: UsernotfoundComponent,
  },
  { path: "**", canActivate: [LoginGuard], component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
