<!-- <div id="cover-spin" *ngIf="loader"></div> -->
<div style="z-index: 100000000000" *ngIf="isLoading" class="main-cube">
  <div class="qassure-cube-grid">
    <div class="qassure-cube qassure-cube1"></div>
    <div class="qassure-cube qassure-cube2"></div>
    <div class="qassure-cube qassure-cube3"></div>
    <div class="qassure-cube qassure-cube4"></div>
    <div class="qassure-cube qassure-cube5"></div>
    <div class="qassure-cube qassure-cube6"></div>
    <div class="qassure-cube qassure-cube7"></div>
    <div class="qassure-cube qassure-cube8"></div>
    <div class="qassure-cube qassure-cube9"></div>
  </div>
</div>
