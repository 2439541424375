<style>
    *{
        font-family: 'Roboto' , sans-serif;
        background-color: #9beaf2;
        /* #26d1e4 */
    }
    #body
    {
        padding-top: 10%;
        text-align: center;
    }
    </style>
    <body >
    <div id="body">
        <img src="../../../assets/images/maintenance.png" style ="width:auto;height:100px"  alt="server under maintenance">
            <h1>
            :) Oops!</h1>
        <h2>
            Temporarily down for maintenance</h2>
        <h1>
            We’ll be back soon!</h1>
        <div>
            <p>
                Sorry for the inconvenience but we’re performing some maintenance at the moment.
                we’ll be back online shortly!</p>
            <p>
                — Team QAssure</p>
        </div>
    </div>
    </body>
    