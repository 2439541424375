import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CookieService } from "ngx-cookie";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  isUserAllowed = true;
  istokenExpired: boolean;
  constructor(
    private http: HttpClient,
    private _cookieService: CookieService
  ) {}

  // to retrieve auth_token
  public getAuthToken() {
    // return localStorage.getItem("auth_token")
    return localStorage.getItem("auth_token");
  }

  /**
   * Gets an token from backend which will be used for authorization
   * @param access_token AccessToken received from google Api
   */
  public getAuthorizedId(idtoken) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${idtoken}`,
    });

    const requestOptions = { headers: headers };
    return this.http.post(
      `${environment.base_url}swap_token/`,
      {
        type: null
      },
      requestOptions
    );
  }

  /**
   * Retrieve Designation of the user
   * @param email_id Email id of the user
   */
  public getDesignation(email_id) {
    return this.http.post(`${environment.base_url}get_designation/`, {
      emp_id: email_id,
    });
  }

  //Get Teams

  // getTeams(emp_id: string) {
  //   let body = {
  //     emp_id: null,
  //   };
  //   body.emp_id = emp_id;
  //   return this.http.post(environment.base_url + "admin_project_list", body);
  // }
  expireSessionOnLogout(emp_id: string) {
    let body = {
      emp_id: null,
    };
    body.emp_id = emp_id;
    return this.http.post(environment.base_url + "session_expire", body);
  }
}
