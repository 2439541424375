import { Component, ElementRef, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api/menuitem";
import { LoaderService } from "./services/loader.service";
import * as Highcharts from "highcharts";
import { SharedService } from "./services/shared/shared.service";

import {
  SocialUser,
  SocialAuthService,
  GoogleLoginProvider,
} from "@abacritt/angularx-social-login";
import { NavigationStart, Router } from "@angular/router";
const noData = require("highcharts/modules/no-data-to-display");
noData(Highcharts);
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private _sharedService: SharedService,private _elementRef: ElementRef) {}

  ngOnInit() {
    this._elementRef.nativeElement.removeAttribute("ng-version");
    let designation: string = this._sharedService.getDesignation();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        window["ga"]("set", "page", event.url);
        window["ga"]("send", "pageview");
        if (designation == "auditor") {
          if (
            event.url !== "/verification" &&
            event.url !== "/project-details" &&
            event.url !== "/list-projects"
          ) {
            this.router.navigateByUrl("/list-projects");
          }
        }
      }
    });
  }
}
