import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, pipe, throwError } from "rxjs";
import { Router } from "@angular/router";
import { ApiService } from "../services/token/api.service";
import {
  catchError,
  delay,
  finalize,
  retryWhen,
  scan,
  tap,
} from "rxjs/operators";
// import { LoaderService } from "../services/loader.service";
import { SharedService } from "../services/shared/shared.service";
import { CookieService } from "ngx-cookie";
import { SocialAuthService } from "@abacritt/angularx-social-login";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private _sharedService: SharedService,
    private _tokenService: ApiService,
    private authService: SocialAuthService,
    private _router: Router,
    private _cookieService: CookieService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this._tokenService.getAuthToken();
    // if the token is  stored in localstorage add it to http header
    if (token) {
      // token is used for user authorization
      request = request.clone({
        headers: request.headers.set("Authorization", `Bearer ${token}`),
      });
    }
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        }),
        tap(
          (event: HttpEvent<any>) => {
            // if (event instanceof HttpResponse) {
            //   this.removeRequest(request);
            // }
            return event;
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              // this.removeRequest(request);
              if (err.status === 404) {
                // redirect to the logout route with Session Expire Error Msg
                this._tokenService.isUserAllowed = false;
              } else if (err.status === 400) {
                this._sharedService.errorNotifier.next({
                  errorType: "Bad Request",
                  error: err,
                });
              } else if (err.status === 403 || err.status === 401) {
                // &&
                // err["statusText"] === "Forbidden"
                this._cookieService.removeAll();
                localStorage.clear();
                this.authService.signOut();
                this._router.navigate(["/home"]);
                // redirect to the logout route with Session Expire Error Msg
                this._tokenService.istokenExpired = true;
              } else if (err.status === 500) {
                this._sharedService.errorNotifier.next({
                  errorType: "Internal Server Error",
                  error: err,
                });
              }
            }
          }
        )
      )
      .pipe(
        finalize(() => {
          // console.log('finalize')
          // this.removeRequest(request);
          // if (this.requests.length === 0)
          // this._loaderService.hide()
        })
      );
  }
}
